<template lang="html">
  <div id="promotions-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-page-title">
          <h6 class="title-s1">Promotions</h6>
        </div>

        <div class="col-12 col-promo">
          <div class="box-promo">
            <div class="col-lg-6 col-info">
              <h4 class="title">Promotion title</h4>
              <h5 class="subtitle">Promotion Subtitle</h5>
              <p>
                <router-link class="btn btn-s2 bg-redl" to="/promotions/1">See promotion</router-link>
              </p>
            </div>

            <div class="col-lg-6 placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/promotions/banner-1.jpg)' }"></div>
          </div>
        </div>

        <div class="col-12 col-promo">
          <div class="box-promo">
            <div class="col-lg-7 col-xl-6 col-info">
              <h4 class="title">Promotion title</h4>
              <h5 class="subtitle">Promotion Subtitle</h5>
              <p>
                <router-link class="btn btn-s2 bg-redl" to="/promotions/1">See promotion</router-link>
              </p>
            </div>

            <div class="col-lg-5 col-xl-6 placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/promotions/banner-2.jpg)' }"></div>
          </div>
        </div>

        <div class="col-12 col-promo">
          <div class="box-promo">
            <div class="col-lg-7 col-xl-6 col-info">
              <h4 class="title">Promotion title</h4>
              <h5 class="subtitle">Promotion Subtitle</h5>
              <p>
                <router-link class="btn btn-s2 bg-redl" to="/promotions/1">See promotion</router-link>
              </p>
            </div>

            <div class="col-lg-5 col-xl-6 placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/promotions/banner-3.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
