import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', component: require(page+'home.vue').default },
      { path: '/contact', component: require(page+'contact/index.vue').default, meta:{title:"Contact"}},
      { path: '/categories', component: require(page+'categories/index.vue').default, meta:{title:"Categories"}},
      { path: '/about', component: require(page+'about-us/index.vue').default, meta:{title:"Empresa"}},
      { path: '/notice-of-privacy', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},
      { path: '/terms-and-conditions', component: require(page+'text-pages/tos.vue').default, meta:{title:"Terms and Conditions"}},

      { path: '/promotions', component: require(page+'promotions/index.vue').default, meta:{title:"Promotions"}},
      { path: '/promotions/:id', component: require(page+'promotions/detail.vue').default, meta:{title:"Promoción"}},

      { path: '/products/:id?', component: require(page+'products/results.vue').default, meta:{title:"Productos"}},
      { path: '/product/:id', component: require(page+'products/detail.vue').default, meta:{title:"Producto"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Log in"}},
      { path: '/reset-password', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Reset password"}},
      { path: '/register', component: require(page+'user-access/register.vue').default, meta:{title:"Register"}},

      { path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}},

      { path: '/user', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'My data' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Change Password' }
  			},
        {
            path: 'historial-compras',
            component: require(page+'usuario-cuenta/historial-compras.vue').default,
            meta:{ title: 'My orders' }
        },
        {
            path: 'pedido/:id',
            component: require(page+'usuario-cuenta/orderdetails.vue').default,
            meta:{ title: 'My orders' }
        },
  	  	]
  		},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Ariel Glatt Kosher Mart", filter: (title)=>{ return title+" - Ariel Glatt Kosher Mart"; } }
);

// export {routes};
export default MyRouter;
