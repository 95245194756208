<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.png" alt="">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse" class="t-150">
            <i class="far fa-bars"></i>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <li class="nav-item simple-item">
                <a class="nav-link" @click="isPActive = !isPActive">Products <i class="fas fa-sort-down ic-down"></i></a>
              </li>
              <b-nav-item class="simple-item" to="/about">About</b-nav-item>
              <b-nav-item class="simple-item" to="/categories">Categories</b-nav-item>
              <b-nav-item class="simple-item" to="/promotions">Promotions</b-nav-item>
              <b-nav-item class="simple-item" to="/contact">Contact</b-nav-item>

              <b-nav-item-dropdown class="simple-item dropdown-account" right>
                <template #button-content>
                  <span><i class="far fa-user-circle"></i> My account</span>
                </template>
                <b-dropdown-item to="/login">Log in</b-dropdown-item>
                <b-dropdown-item to="/register">Register</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="simple-item cart" to="/cart">
                <span class="icon"><i class="fas fa-shopping-cart"></i><i class="num">0</i></span>
                <span class="d-lg-none ml-2">My cart</span>
              </b-nav-item>

              <!-- <li class="nav-item networks-item">
                <div class="nav-link">
                  <a class="btn-network"><img src="public/images/shared/app-store.svg" /></a>
                  <a class="btn-network"><img src="public/images/shared/google-play.svg" /></a>
                </div>
              </li> -->
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <router-link class="t-150 header-extra" to="/login">
      Express Reordering
    </router-link>

    <!-- Products menu -->
    <div class="t-250 products-menu" v-bind:class="{ active: isPActive }">
      <div class="menu-bg" @click="isPActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isPActive = false">
          <h4>PRODUCTS</h4>
          <br>
          <h5>Categories</h5>
          <i class="far fa-chevron-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <div v-if="category.subcategories.length" class="btn-toggle">
              <router-link :to="'/products/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
              <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
            </div>
            <router-link v-else class="btn-toggle-fake" :to="'/products/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
          </b-card-header>

          <b-collapse v-if="category.subcategories.length" :id="'accordion-'+pcatIndex"  accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ul class="ul-sub-options">
                <li v-for="subcategory in category.subcategories">
                  <router-link class="t-250" :to="'/products/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!--  -->

  </header>
</template>

<script>
export default {
  data() {
    return {
      isPActive: false,
      categories:[
        { id: 1, name: 'Bakery',
          subcategories: [
            { id: 2, name: 'Subcategory item' },
            { id: 3, name: 'Subcategory item' },
            { id: 4, name: 'Subcategory item' },
          ]
        },
        { id: 10, name: 'Candy & Sweets',
          subcategories: [
            { id: 5, name: 'Subcategory item' },
            { id: 8, name: 'Subcategory item' },
            { id: 9, name: 'Subcategory item' },
          ]
        },
        { id: 14, name: 'Fresh fish',
          subcategories: [
            { id: 5, name: 'Subcategory item' },
            { id: 8, name: 'Subcategory item' },
            { id: 9, name: 'Subcategory item' },
          ]
        },
        { id: 17, name: 'Frozen',
          subcategories: [
            { id: 5, name: 'Subcategory item' },
            { id: 8, name: 'Subcategory item' },
            { id: 9, name: 'Subcategory item' },
          ]
        },
        { id: 17, name: 'Grocery',
          subcategories: [
            { id: 5, name: 'Subcategory item' },
            { id: 8, name: 'Subcategory item' },
            { id: 9, name: 'Subcategory item' },
          ]
        },
        { id: 17, name: 'Meat',
          subcategories: [
            { id: 5, name: 'Subcategory item' },
            { id: 8, name: 'Subcategory item' },
            { id: 9, name: 'Subcategory item' },
          ]
        },
      ],
    }
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
    }
  }
}
</script>
