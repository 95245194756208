<template lang="html">
  <footer class="placed-backg" id="footer">

    <section class="newsletter-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-2 col-logo">
            <router-link to="/"><img src="public/images/logo.png" alt="Kosher Market"></router-link>
          </div>

          <div class="col-lg col-newsletter">
            <div class="row align-items-center">
              <div class="col-lg col-text">
                <h5>Subscribe to the Newsletter!</h5>
              </div>

              <div class="col-lg col-form">
                <b-form class="row no-gutters" @submit="onSubmit">
                  <b-form-group class="col">
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      placeholder="Your email"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-button type="submit" class="col btn-snewsletter">Subscribe</b-button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-sm-6 col-lg-3 col-main-1">
            <p class="mt-3 t-w-break">
              <a class="link-icon email" href="mailto:contact@domain.com"><i></i> contact@domain.com</a>
            </p>
            <p class="mt-2 t-w-break">
              <a class="link-icon help"><i></i> +13239649438</a>
            </p>
            <p class="mt-2 t-w-break">
              <span class="link-icon map"><i></i> <span>304 N La Brea Ave,<br />Los Angeles, CA 90036</span></span>
            </p>
          </div>

          <div class="col-lg-6 col-main-2">
            <div class="row justify-content-center">
              <div class="col-6 col-sm-4 col-xl-3 col-menu">
                <h5 class="title">General</h5>

                <p>
                  <router-link to="/about">About</router-link><br />
                  <!-- <router-link to="">Partnership</router-link><br /> -->
                  <router-link to="">Help</router-link><br />
                  <router-link to="/contact">Contact</router-link>
                </p>
              </div>

              <div class="col-6 col-sm-4 col-xl-3 col-menu">
                <h5 class="title">Site</h5>

                <p>
                  <router-link to="/products/1">Products</router-link><br />
                  <router-link to="/categories">Categories</router-link><br />
                  <router-link to="/products/1">Promotions</router-link>
                </p>
              </div>

              <div class="col-6 col-sm-4 col-menu">
                <h5 class="title">Help links</h5>

                <p>
                  <router-link to="/login">Log in</router-link><br />
                  <router-link to="/register">Register</router-link><br />
                  <router-link to="/faq">FAQs</router-link><br />
                  <!-- <router-link to="">Soporte técnico</router-link> -->
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-3 col-main-3">
            <!-- <p>
              <a class="_btn-net ig t-150" target="_blank"><span><i class="fab fa-instagram"></i></span></a>
              <a class="_btn-net fb t-150" target="_blank"><span><i class="fab fa-facebook-f"></i></span></a>
              <a class="_btn-net tw t-150" target="_blank"><span><i class="fab fa-twitter"></i></span></a>
              <a class="_btn-net lk t-150" target="_blank"><span><i class="fab fa-linkedin-in"></i></span></a>
            </p> -->

            <p>
              <router-link class="mini-text" to="/notice-of-privacy">Notice of Privacy</router-link><br />
              <router-link class="mt-2 mini-text" to="/terms-and-conditions">Terms and Conditions</router-link><br />
              <span class="d-inline-block mt-2 mini-text">Copyright © {{ currentYear }} Kosher Market</span>
            </p>
          </div>
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },

      currentYear: 0,
      info: {}
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();
  }
}
</script>
