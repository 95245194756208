<template lang="html">
  <div id="services-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h6 class="title-s1">Categories</h6>
        </div>

        <div class="col-12 col-md-6 col-lg-4 col-xl-3 box-service-sample-1 x-h" v-for="(s, shInx) in services" :key="'shInx-'+shInx">
          <router-link class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+s.imageUrl+')' }" to="/products/1">
            <div class="placed-backg box-text">
              <h5>{{ s.name }}</h5>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        { imageUrl: 'public/images/pages/services/service-1.jpg', name: 'Bakery' },
        { imageUrl: 'public/images/pages/services/service-2.jpg', name: 'Fresh fish' },
        { imageUrl: 'public/images/pages/services/service-3.jpg', name: 'Grocery' },
        { imageUrl: 'public/images/pages/services/service-4.jpg', name: 'Meat' },
        { imageUrl: 'public/images/pages/services/service-5.jpg', name: 'Frozen' },
        { imageUrl: 'public/images/pages/services/service-6.jpg', name: 'Candy & Sweets' },
        { imageUrl: 'public/images/pages/services/service-1.jpg', name: 'Bakery' },
        { imageUrl: 'public/images/pages/services/service-2.jpg', name: 'Fresh fish' },
      ],
    }
  }
}
</script>
